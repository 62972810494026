// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

@import '~antd/dist/antd.css';

// If you want to add something do it here
@import "custom";

// Import calendar style
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

// chat
@import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

@import 'react-phone-number-input/style.css'