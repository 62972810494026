// Here you can add other styles

body {
  font-size: medium;
}

.table td {
  padding: 0.25rem 0.75rem;
  vertical-align: middle;
}

.image-modal {
  margin-bottom: 2px;
  border: 0.5px solid #d8dbe0;
}

#model-suggestion {
  border: 0.5px solid #d8dbe0;
  margin-top: -15px;
  max-height: 98px;
  overflow-y: auto;
}

#model-suggestion div {
  border: 0.5px solid #d8dbe0;
  padding: 5px;
}

#model-suggestion div {
  cursor: pointer;
}

.address-suggestion {
  cursor: pointer;
  padding: 5px;
  margin-bottom: 0;
}

.suggestion-hover,
.address-suggestion:hover {
  background-color: #d8dbe0;
}

@media (max-width: 767px) {
  .custom-break {
    display: none;
  }
}

.Input {
  width: 70%;
  height: 38.4px;
  align-items: "center";
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: auto;
  box-shadow: 0 0 0 1px transparent;

  &:focus {
    box-shadow: 0 0 0 1px #2684ff;
    border-color: #2684ff;
    outline: none;
  }
}

.Ul {
  list-style-type: none;
  padding: 0;
  width: 70%;
  background-color: #ffffff;
  box-shadow: 3px 3px 15px 0px rgba(153, 153, 153, 0.3);
  border-radius: 4px;
}

.Li {
  background-color: transparent;
  padding: 7px;

  &:hover {
    background-color: #deebff;
    cursor: pointer;
  }

  &:active {
    background-color: #b2d4ff;
  }
}

.create-invoice__services-table,
.create-invoice__payments-table {
  overflow: auto;

  .ant-table-title {
    padding: 7px 10px 0px 10px;
  }

  .ant-table-thead > tr > th {
    padding: 10px;
  }

  .ant-table-tbody > tr > td {
    padding: 5px 10px;

    .btn {
      padding: 3px 10px;
    }
  }
}

.invoice-add-modal,
.invoice-edit-modal,
.quote-create-modal,
.quote-edit-modal {
  .modal-dialog {
    max-width: unset;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }
}

.p-10 {
  padding: 10px;
}

.p-5-10 {
  padding: 5px 10px;
}

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.w-60 {
  width: 60px;
}

.w-70 {
  width: 70px;
}

.text-left {
  float: left;
}

.text-right {
  float: right;
}

.tb-services {
  line-height: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #111;
  margin-bottom: 10px;

  &__thead {
    font-weight: bold;
    border-bottom: 1px solid #111;
  }

  &__tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__tr {
    display: flex;
    align-items: top;
    border-bottom: 0.5px dotted #111;

    & > div {
      padding: 10px;
      text-align: left;
      overflow-wrap: break-word;
    }

    & > div:nth-child(1) {
      width: 45%;
    }

    & > div:nth-child(2) {
      width: 10%;
    }

    & > div:nth-child(3),
    & > div:nth-child(4),
    & > div:nth-child(5) {
      width: 15%;
    }
  }

  &__tr_no-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px dotted #111;

    & > div {
      padding: 10px;
      min-width: 100px;
      text-align: left;
      overflow-wrap: break-word;
    }

    & > div:last-child {
      text-align: center;
    }
  }

  &__tr:last-child,
  &__tr_no-price:last-child {
    border-bottom: unset;
  }
}

.item-removed {
  color: red;
  font-weight: bold;
  font-style: italic;
  text-decoration: line-through;
}

.uploaded-image {
  position: relative;

  &:hover > .image-controls {
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    z-index: 1001;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .icon-white {
      color: white;
      cursor: pointer;
    }
  }
}

.PhoneInputInput {
  height: 32px;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
